/**steps
 * = Accordions
 */

.step{
    position: relative;
    margin-bottom: 4rem;

    .step-shape{
        display: inline-flex;
        width: $step-shape-size;
        height: $step-shape-size;
        text-align: center;
        @include align-items(center);
        @include justify-content(center);

        .step-icon{
            span, svg {
                font-size: $icon-size;
            }
        }

        .step-number{
            position: absolute;
            font-weight: $font-weight-bold;
            top: -10px;
            width: 32px;
            height: 32px;
            border-width: $step-border-width;
            border-style: solid;
            line-height: 1.7;
        }

        &.bordered {
            border-width: $step-border-width;
            border-style: solid;

            .step-number {
                background-color: $white;
                top:-12px;
            }
        }

        &.rounded-circle{
            .step-number{
                margin-right:1.275rem;
            }
        }

        &.rounded{
            .step-number{
                @include border-radius($border-radius);
            }
        }

        &.organic-radius{
            .step-number{
                margin-right:1.25rem;
                @include border-radius($organic-radius);
            }
        }
    }

    .icon-lg{
        font-size: $step-icon-size;
    }
}

// Icon (svg) organic-shapes fill colors
@each $color, $value in $theme-colors {
    .layer-#{$color}{ 
        color: rgba( $value, .15 );
    }
}