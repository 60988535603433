
.headroom {
    will-change: transform;
    background-color: inherit;
    @include transition($transition-base);
}
.headroom--pinned {
	@extend .position-fixed;
    transform: translateY(0%);
}
.headroom--unpinned {
	@extend .position-fixed;
    transform: translateY(-100%);
}

.headroom--not-top {
	padding-top: 1rem;
    padding-bottom: 1rem;

    @each $color, $value in $theme-colors {
        &.navbar-theme-#{$color} {
            background-color: $value;

            .navbar-brand-light {
                display: none;
            }
            .navbar-brand-dark {
                display: block;
            }

            .nav-link {
                color: $navbar-dark-color;

                &:hover {
                    color: $navbar-dark-hover-color;
                }
            }
        }
    }

    &.navbar-light {
        .navbar-nav > .nav-item.show > .nav-link {
            color: $white;
        }
        .navbar-toggler-icon {
            background-image: $navbar-dark-toggler-icon-bg;
        }
    }
}