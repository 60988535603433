/**
 * = Cards
 */

.card-img,
.card-img-top {
    border-radius:0
}

.card-img,
.card-img-bottom {
    border-radius:0
}


// Card with blockquotes

.card {
    .card-blockquote {
        position: relative;
        padding: 2rem;

        .svg-bg {
            display: block;
            position: absolute;
            width: 100%;
            height: 95px;
            top: -94px;
            left: 0;
        }
    }

    .profile-thumbnail {
        width: 10rem;
        height: 10rem;
        img{
            border: $border-width-md solid;
        }
        &.small-thumbnail{
            width: 8rem;
            height: 8rem;
        }
    }

    .profile-cover{
        @include background-image(no-repeat, cover); 
        height: 175px;
    }

    .card-footer{
        .btn-block{
            border-radius: 0;
        }
    }

    .price-list{
        .list-group-item{
            span{
                width: 23px;
                font-size: $font-size-sm;
            }
        }
    }

    &.rounded-right-0{
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
    &.rounded-left-0{
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
}

// Animated cards

.card-lift-hover {
    &:hover {
        transform: translateY(-20px);
        @include transition($transition-base);
    }
}

/**
 * = Profile cards
*/

//Rotating Profile Card
.rotating-card{

    .card-rotate{
        background: transparent;
        box-shadow: none;

        &:after{
            display: none;
        }
    }

    .card {
        @include transitions(.8s,$transition-bezier-card);
        @include transform-style(preserve-3d);
        position: relative;

        .back, .front {
            @include display-flex();
            @include flex-direction(column);
            @include align-content(center);
            @include justify-content(center);
            @include backface-visibility(hidden);
            position: absolute;
            @include border-radius($border-radius);
            top: 0;
            left: 0;

            .card-body{
                justify-content:center;
                align-content:center;
                @include display-flex();
                @include flex-direction(column);

                .card-subtitle{
                    color: $primary;
                }
                .social-buttons{
                    margin-bottom: 0;
                    padding: 0;
                    list-style: none;
            
                    li{
                        display:inline-block;
            
                        a{
                            &.btn{
                                padding:$card-social-padding;
                            }
                        }
                    }
                }
            }
        }

        .front {
            z-index: 2;
            position: relative;
            .profile-image{
                width: 9rem;
                height: 9rem;
                margin: 0 auto;
                img{
                    border-width:$border-width-md;
                    border-style:solid;
                    padding: .25rem;
                }
            }
        }

        .back {
            @include rotateY-180();
            z-index: 5;
            text-align: center;

            width: 100%;
            height: 100%;

            &.back-background{
                &:after{
                    position: absolute;
                    z-index: 1;
                    width: 100%;
                    height: 100%;
                    display: block;
                    left: 0;
                    top: 0;
                    content: "";
                    background-color: rgba(0,0,0,.56);
                    @include border-radius($border-radius);
                }
                .card-body{
                    position: relative;
                    z-index: 2;
                }
            }
            .card-footer{
                .btn{
                    margin: 0;
                }
            }

            .card-body{
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }

    &:not(.manual-flip):hover{
        .card{
            @include rotateY-180();
        }

    }


    &.hover.manual-flip{
        .card{
            @include rotateY-180();
        }
    }

    .card-profile &{
        .front{
            text-align: left;
        }
    }
}

.back-background{
    .card-body{
        min-height: auto;
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

.back-background,
.front-background{
    background-position: center center;
    background-size: cover;
}

/*       Fix bug for IE      */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rotating-card-container .card .front,
    .rotating-card-container .card .back{
        -ms-backface-visibility: visible;
        backface-visibility: visible;
    }

    .rotating-card-container .card .back {
        visibility: hidden;
        transition: visibility .3s cubic-bezier(0.34, 1.45, 0.7, 1);
    }
    .rotating-card-container .card .front{
        z-index: 4;
    }

    .rotating-card-container:not(.manual-flip):hover .card .back,
    .rotating-card-container.manual-flip.hover .card .back{
        z-index: 5;
        visibility: visible;
    }
}
        
.page-preview {

    display: block;
    position: relative;

    .show-on-hover {
        position: absolute;
        bottom: -25px;
        background: rgba($dark, .85);
        padding: 10px 0;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        width: calc(100% + 28px);
        left: -14px;
        opacity: 0;
        @include transition(.2s);
    }

    &:hover {
        .show-on-hover {
            z-index: 99;
            opacity: 1;
        }
    }
}